<template>
  <div class="unit-set main-panel" v-loading="isLoading">
    <h3 class="panel-title">单位设置</h3>
    <div class="list">
      <div class="list-item" v-for="item in list" :key="item.id">
        <span @click="openLayer(item)">{{ item.name }}</span>
        <el-popconfirm title="确认删除吗?" confirmButtonText='确认' cancelButtonText='取消' @confirm="del(item.id)">
          <template #reference>
            <i class="del el-icon-circle-close" title="删除" />
          </template>
        </el-popconfirm>
      </div>
    </div>
    <div class="input-wrap">
      <el-input v-model.trim="name" size="mini" placeholder="单位" @keyup.enter="add"></el-input>
      <i class="add el-icon-plus" title="增加" @click="add" />
    </div>

    <my-layer v-if="Object.keys(layerInfo).length"
              :title="layerInfo.layerTitle"
              :component="layerComponent"
              @handle="getList"
              :layer-info="layerInfo"
              @close="layerInfo = {}" />
  </div>
</template>

<script>
import {shallowRef} from "vue";
import MyLayer from "@/components/MyLayer/MyLayer";

export default {
  components: {MyLayer},
  data() {
    return {
      name: "",

      list: [],

      layerComponent: undefined,
      layerInfo: {},

      isLoading: false
    }
  },

  mounted() {
    this.getList()
  },

  methods: {
    async getList() {
      this.isLoading = true
      const res = await this.$api.Base.Unit.getList().catch(() => {})
      this.isLoading = false
      // console.log(res)
      if (res?.code === 200) {
        this.list = res.data
      } else {
        this.$message.error(res?.msg || "获取单位列表失败")
      }
    },

    async add() {
      if (!this.name || this.isLoading) return false;
      this.isLoading = true
      const res = await this.$api.Base.Unit.add({companyName: this.name}).catch(() => {})
      this.isLoading = false
      if (res?.code === 200) {
        this.name = ""
        this.$message.success("新增成功")
        await this.getList()
      } else {
        this.$message.error("新增失败")
      }
    },

    async del(id) {
      this.isLoading = true
      const res = await this.$api.Base.Unit.del(id).catch(() => {})
      this.isLoading = false
      if (res?.code === 200) {
        this.$message.success("删除成功")
        await this.getList()
      } else {
        this.$message.error(res?.msg || "删除失败")
      }
    },

    async openLayer(info) {
      this.layerInfo = {...info, layerTitle: "单位修改"}
      this.layerComponent = shallowRef((await import("./update/update")).default)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "unit-set";
</style>
